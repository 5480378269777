<!--
 * @Author: your name
 * @Date: 2021-04-14 16:15:07
 * @LastEditTime: 2021-04-15 15:47:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\components\navigation\index.vue
-->
<template>
    <div>
        <div class="nac">
            <van-icon v-if="noleft!=true" @click="$router.go(-1)" size=".35rem" name="arrow-left"/>
            <div class="xxm">{{ msg }}</div>
            <van-icon v-if="right" size=".35rem" name="arrow"/>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            right: Boolean,
            msg: String,
            noleft:Boolean,
        },
       
    };
</script>
<style scoped>
   

    .nac > :last-child {
        margin-right: 0.32rem;
    }

     .xxm {
        position: absolute;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .nac > :first-child {
        margin-left: 0.32rem;
    }

    .nac {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        font-size: 0.34rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
        padding: 0.3rem 0;
        position: relative;
        border-bottom-color: #ebedf0;
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
</style>
