/*
 * @Author: your name
 * @Date: 2021-04-08 19:17:57
 * @LastEditTime: 2021-04-08 19:19:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\util\base.js
 */
/**
 * 接口域名的管理
 */
 const base = {
    // apiurl: 'https://api.luobolieshou.com', //接口地址
    // test: 'https://api.luobolieshou.com', //接口地址
    // url:'https://copm.luobolieshou.com',  //线上访问地址（非接口地址）
    // linkurl: "https://api.luobolieshou.com/upload/20210120/5beec401d087ff452618986efdcdd94b.png", //分享图标

    // formal:'https://api.luobolieshou.com'  // 测试
    formal:'https://api.luobolieshou.com',  // 正式
   
}

export default base;
