/*
 * @Author: your name
 * @Date: 2021-04-08 17:40:31
 * @LastEditTime: 2021-04-15 21:25:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\router.js
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path:"/record",
        name:"record",
        component:() => import("@/views/editResume/record.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/resume2",
        name:"resume2",
        component:() => import("@/views/editResume/resume2.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/school2",
        name:"school2",
        component:() => import("@/views/editResume/school2.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/school",
        name:"school",
        component:() => import("@/views/editResume/school.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/resume1",
        name:"resume1",
        component:() => import("@/views/editResume/resume1.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    
    {
        path:"/station",
        name:"station",
        component:() => import("@/views/editResume/station.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/intention",
        name:"intention",
        component:() => import("@/views/editResume/intention.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/resume",
        name:"resume",
        component:() => import("@/views/editResume/resume.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/editInfo",
        name:"editInfo",
        component:() => import("@/views/editResume/editInfo.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/editUpwdSuc",
        name:"editUpwdSuc",
        component:() => import("@/views/editMsg/editUpwdSuc.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/authentication",
        name:"authentication",
        component:() => import("@/views/editMsg/authentication.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/editEmail",
        name:"editEmail",
        component:() => import("@/views/editMsg/editEmail.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/editUpwd",
        name:"editUpwd",
        component:() => import("@/views/editMsg/editUpwd.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/editName",
        name:"editName",
        component:() => import("@/views/editMsg/editName.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/editWx",
        name:"editWx",
        component:() => import("@/views/editMsg/editWx.vue"),
        meta:{
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/",
        name:"index",
        component:()=> import("@/views/home/index.vue"),
        meta: {
            title: '登录',
            //keepAlive: true, // 需要被缓存
            //flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path:"/login",
        name:"login",
        component:()=> import("@/views/login/login.vue"),
        meta: {
            title: '登录',
            //keepAlive: true, // 需要被缓存
            flag: true, //  false  显示 ; true 不显示
        }
    },
    {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
            title: '首页',
            //keepAlive: true, // 需要被缓存
           // flag: true, //是否显示table栏
        }
    },
    {
        path: "/message",
        name: "message",
        component: () => import("@/views/message/index.vue"),
        meta: {
            title: '授权中心',
            //keepAlive: true, // 需要被缓存
            // flag: true, //是否显示table栏
        }
    },
    {
        path: "/onlineTag",
        name: "onlineTag",
        component: () => import("@/views/onlineTag/index.vue"),
        meta: {
            title: '技能云',
            //keepAlive: true, // 需要被缓存
            // flag: true, //是否显示table栏
        }
    },
    {
        path: "/divTagEdit",
        name: "divTagEdit",
        component: () => import("@/views/onlineTag/divTagEdit.vue"),
        meta: {
            title: '自定义编辑',
            //keepAlive: true, // 需要被缓存
             flag: true, //是否显示table栏
        }
    },
    {
        path: "/editTag",
        name: "editTag",
        component: () => import("@/views/onlineTag/editTag.vue"),
        meta: {
            title: '编辑技能云',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    {
        path: "/user",
        name: "user",
        component: () => import("@/views/user/index.vue"),
        meta: {
            title: '个人中心',
            //keepAlive: true, // 需要被缓存
            // flag: true, //是否显示table栏
        }
    },
    {
        path: "/statusChange",
        name: "statusChange",
        component: () => import("@/views/user/statusChange.vue"),
        meta: {
            title: '身份切换',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    {
        path: "/companySx",
        name: "companySx",
        component: () => import("@/views/usermsg/companySx.vue"),
        meta: {
            title: '搜索',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/userResume",
        name: "userResume",
        component: () => import("@/views/usermsg/userResume.vue"),
        meta: {
            title: '我的简历',
            //keepAlive: true, // 需要被缓存
            //flag: true, //是否显示table栏
        },
    },
    {
        path: "/commentDetail",
        name: "commentDetail",
        component: () => import("@/views/usermsg/commentDetail.vue"),
        meta: {
            title: '评论',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/companyMoreDetail",
        name: "companyMoreDetail",
        component: () => import("@/views/usermsg/companyMoreDetail.vue"),
        meta: {
            title: '评论',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/moreComment",
        name: "moreComment",
        component: () => import("@/views/usermsg/moreComment.vue"),
        meta: {
            title: '企业评论',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/avatar",
        name: "avatar",
        component: () => import("@/views/user/avatar.vue"),
        meta: {
            title: '修改头像',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },

    // {
    //     path: "/moreCommentDetail",
    //     name: "moreCommentDetail",
    //     component: () => import("@/views/usermsg/moreCommentDetail.vue"),
    //     meta: {
    //         title: '评价详情',
    //         //keepAlive: true, // 需要被缓存
    //         flag: true, //是否显示table栏
    //     },
    // },
    {
        path: "/recomment",
        name: "recomment",
        component: () => import("@/views/usermsg/recomment.vue"),
        meta: {
            title: '我要回评',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    
    {
        path: "/companyList",
        name: "companyList",
        component: () => import("@/views/usermsg/companyList.vue"),
        meta: {
            title: '我的企业',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/companyDetail",
        name: "companyDetail",
        component: () => import("@/views/usermsg/companyDetail.vue"),
        meta: {
            title: '我的企业',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/viewInfo",
        name: "viewInfo",
        component: () => import("@/views/usermsg/viewInfo.vue"),
        meta: {
            title: '个人信息',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/userProfession",
        name: "userProfession",
        component: () => import("@/views/usermsg/userProfession.vue"),
        meta: {
            title: '我的职业档案',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/about",
        name: "about",
        component: () => import("@/views/usermsg/about.vue"),
        meta: {
            title: '关于萝卜猎手',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    {
        path: "/usermsg",
        name: "usermsg",
        component: () => import("@/views/usermsg/index.vue"),
        meta: {
            title: '个人信息',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        },
    },
    //  首页搜索页面
    {
        path: "/search",
        name: "search",
        component: () => import("@/views/home/search.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
   
    // 我的企业列表
    {
        path: "/enterpriseList",
        name: "enterpriseList",
        component: () => import("@/views/home/enterpriseList.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    // 我的企业详情
    {
        path: "/enterpriseDetail",
        name: "enterpriseDetail",
        component: () => import("@/views/home/enterpriseDetail.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    // 消息详情页面
    // messageDetail
    {
        path: "/messageDetail",
        name: "messageDetail",
        component: () => import("@/views/message/messageDetail.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    {
        path: "/addInfo",
        name: "addInfo",
        component: () => import("@/views/message/addInfo.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    // {
    //     path: "/resumeToIntroduce",
    //     name: "resumeToIntroduce",
    //     component: () => import("@/views/message/resumeToIntroduce.vue"),
    //     meta: {
    //         title: '简历转介绍授权',
    //         //keepAlive: true, // 需要被缓存
    //         flag: true, //是否显示table栏
    //     },
    // },
    // {
    //     path: "/quitNotice",
    //     name: "quitNotice",
    //     component: () => import("@/views/message/quitNotice.vue"),
    //     meta: {
    //         title: '离职通知',
    //         //keepAlive: true, // 需要被缓存
    //         flag: true, //是否显示table栏
    //     },
    // },
    // {
    //     path: "/technique",
    //     name: "technique",
    //     component: () => import("@/views/message/technique.vue"),
    //     meta: {
    //         title: '点亮技能云',
    //         //keepAlive: true, // 需要被缓存
    //         flag: true, //是否显示table栏
    //     },
    // },
    // {
    //     path: "/resumeDownload",
    //     name: "resumeDownload",
    //     component: () => import("@/views/message/resumeDownload.vue"),
    //     meta: {
    //         title: '简历下载',
    //         //keepAlive: true, // 需要被缓存
    //         flag: true, //是否显示table栏
    //     },
    // },
    
    // 编辑技能云
    {
        path: "/choiceTag",
        name: "choiceTag",
        component: () => import("@/views/onlineTag/choiceTag.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    // 新增技能云
    {
        path: "/tagList",
        name: "tagList",
        component: () => import("@/views/onlineTag/tagList.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
      // 新增自定义技能
      {
        path: "/addTagItem",
        name: "addTagItem",
        component: () => import("@/views/onlineTag/addTagItem.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
     // 新增自定义技能
     {
        path: "/addTagItem1",
        name: "addTagItem1",
        component: () => import("@/views/onlineTag/addTagItem1.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    // 新增自定义技能
    {
        path: "/addTag",
        name: "addTag",
        component: () => import("@/views/onlineTag/addTag.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    // 技能详细
    {
        path: "/tagDetail",
        name: "tagDetail",
        component: () => import("@/views/onlineTag/tagDetail.vue"),
        meta: {
            title: '',
            //keepAlive: true, // 需要被缓存
            flag: true, //是否显示table栏
        }
    },
    // 我的企业
    {
      path: "/interprise",
      name: "interprise",
      component: () => import("@/views/interprise/index.vue"),
      meta: {
          title: '我的企业',
          //keepAlive: true, // 需要被缓存
          flag: true, //是否显示table栏
      }
  },


];

const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.afterEach(route => {
    // // 从路由的元信息中获取 title 属性
    if (route.meta.title) {
        document.title = route.meta.title;
        // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
        if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            const hackIframe = document.createElement('iframe');
            hackIframe.style.display = 'none';
            hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
            document.body.appendChild(hackIframe);
            setTimeout(() => {
                document.body.removeChild(hackIframe);
            }, 300)
        }
    }
});

export default router;
