/*
 * @Author: your name
 * @Date: 2021-04-08 19:16:05
 * @LastEditTime: 2021-04-08 19:26:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\util\https.js
 */
import instance from "./http";
import domain from "./base";
import QS from "qs";
// import { Dialog } from "vant";
// import { Toast } from "vant";

const get = (url, params) => {
    return new Promise((resolve, reject) => {
        instance
            .get(`${domain.formal}${url}`, {
                params: params,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
};

const post = (url, params) => {
    let parames = {
        accessToken:'c5b3a63ece84aa883836f09204b3e72e',
        userId: localStorage.getItem("id") != undefined ?  localStorage.getItem("id") : "",
        token:  localStorage.getItem("token") != undefined ? localStorage.getItem("token") : "",
        requestParams: params,
    };
    return new Promise((resolve, reject) => {
        instance
            .post(`${domain.formal}${url}`, QS.stringify(parames))
            .then((res) => {
                // console.error(res)
                // if(JSON.parse(res.data).code == 0){
                //     resolve(res);
                // }else  if(JSON.parse(res.data).code == 40003){
                //     console.log(JSON.parse(res.data).code )
                //    this.$router.push("./login")
                //     return;
                // }
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export default {
    get,
    post,
};
